.navbar {
  display: flex;
  justify-content: space-between;
}

.logo :hover, .logo :focus {
  animation: rotation 2s infinite linear;
  cursor: crosshair;
}

.logo img {
  height: 60px;
  margin-left: 20px;
}

.navlinks {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  margin-right: 2rem;
}

.navlink {
  transition: 1s;
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
}

.navlink a {
  transition: 1s;
  color: rgb(0, 0, 0);
  text-decoration: none;
  letter-spacing: 0.1em;

  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

.navlink a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: rgb(0, 0, 0);
  transition: width 0.8s ease 0s, left 0.3s ease 0s;
  width: 0;
  transform: translate(0, -9px);
}
.navlink a:hover:after,
.navlink a:focus:after {
  width: 100%;
  left: 0;
}

.burger {
  right: 0;
  display: none;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 10;
}

.bur {
  height: 3px;
  margin: 5px 0;
  width: 40px;
  background: black;
  transition: 0.6s;
}

@media (max-width: 950px) {
  .navlinks {
    display: none;
  }
  .burger {
    position: fixed;
    display: block;
  }
  .burger-clicked {
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(150, 191, 209, 1) 0%,
        rgba(206, 187, 213, 1) 35%,
        rgba(194, 214, 201, 1) 64%,
        rgba(180, 195, 214, 1) 100%
      )
      no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .display-none {
    display: none;
  }
  .bur1 {
    transform: translate(0, 8px) rotate(45deg);
  }
  .bur2 {
    transform: translate(0, 0px) rotate(-45deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
