.home {
  margin-top: 2rem;
}

.section {
  overflow: hidden;
  position: relative;
  height: 80vh;
  scroll-snap-align: start;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.section p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.4rem;
}

/* For smaller screen size */
@media (max-width: 1050px) {
  .section {
    scroll-snap-align: none;
    height: auto;
  }
  .scroller {
    display: none;
  }
  .section h2 {
    font-size: 1.5rem;
  }
  .section p {
    font-size: 1rem;
  }
}
