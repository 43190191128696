* {
  font-family: "Space Mono", monospace;
}

body {
  margin: 0;
  background: rgb(150, 191, 209);
  background: linear-gradient(
      90deg,
      rgba(150, 191, 209, 1) 0%,
      rgba(206, 187, 213, 1) 35%,
      rgba(194, 214, 201, 1) 64%,
      rgba(180, 195, 214, 1) 100%
    )
    no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.content {
  max-width: 1400px;
  margin: auto;
  height: 85vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

:focus {
  outline: none;
}

:root {
  --color-one: #bcf5db;
  --color-two: #569f56;
  --color-three: #fad2e7;
  --color-four: #e6aacf;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 0.1px solid #b7b7b7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
