/* Add these styles at the bottom of your styles.css file */

.education-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
    row-gap: 2rem;
  }
  
  .branch {
    display: flex;
    align-items: center;
  }
  
  .vertical-line {
    border-left: 2px solid #ccc;
    height: 50px;
    margin-right: 1rem;
  }
  
  .info {
    text-align: left;
  }
  
  .info h3 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }
  
  .info p {
    font-size: 0.9rem;
    color: #666;
  }
  